var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"roomList"},[_c('el-breadcrumb',{attrs:{"separator":"/"}},[_c('el-breadcrumb-item',[_vm._v("商品列表")])],1),_c('div',{staticClass:"search"},[_c('span',[_vm._v("商品名称:")]),_c('el-input',{attrs:{"placeholder":"请输入商品名称"},model:{value:(_vm.parmes.roomName),callback:function ($$v) {_vm.$set(_vm.parmes, "roomName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"parmes.roomName"}}),_c('span',[_vm._v("活动状态:")]),_c('el-select',{attrs:{"clearable":"","placeholder":"请选择"},on:{"clear":function($event){return _vm.onClear(1)}},model:{value:(_vm.parmes.status),callback:function ($$v) {_vm.$set(_vm.parmes, "status", $$v)},expression:"parmes.status"}},_vm._l((_vm.statusList),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.onSearch}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push({ path: '/linkingActivity', query: { type:'reserve' } })}}},[_vm._v("返回")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.addVisible = true}}},[_vm._v("添加商品")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"border":""},on:{"selection-change":_vm.onSelectChange}},[_c('el-table-column',{attrs:{"align":"center","prop":"advanceProdId","label":"商品Id","width":"120"}}),_c('el-table-column',{attrs:{"align":"center","prop":"title","label":"活动名称"}}),_c('el-table-column',{attrs:{"align":"center","prop":"days","label":"提前预订天数"}}),_c('el-table-column',{attrs:{"align":"center","prop":"prodType","label":"商品类型"}}),_c('el-table-column',{attrs:{"align":"center","prop":"showImg","label":"展示图","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":row.showImg,"preview-src-list":[row.showImg]}})]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"prodName","label":"商品名称","width":"320"}}),_c('el-table-column',{attrs:{"align":"center","prop":"stock","label":"活动库存","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.inputVisible)?_c('el-input',{ref:"saveTagInput",staticClass:"input-new-tag",attrs:{"size":"small"},on:{"input":_vm.onInput,"blur":function($event){return _vm.handleInputConfirm(row)}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleInputConfirm(row)}},model:{value:(row.stock),callback:function ($$v) {_vm.$set(row, "stock", $$v)},expression:"row.stock"}}):_c('el-button',{staticClass:"button-new-tag",on:{"click":function($event){return _vm.onChangeSort(row)}}},[_vm._v(" "+_vm._s(row.stock)+" ")])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"strStatus","label":"状态"}}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"350"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(
              (row.status == 1) &&
              _vm.$store.state.powerList.indexOf('advance:prod:list:modify') !==
                -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onUpAndDownRow(row)}}},[_vm._v("禁用 ")]):(
              row.status == 2 &&
              _vm.$store.state.powerList.indexOf('advance:prod:list:modify') !==
                -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onUpAndDownRow(row)}}},[_vm._v("启用 ")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf('advance:prod:list:delete') !==
              -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onDelRow(row)}}},[_vm._v("删除")]):_vm._e(),(
              _vm.$store.state.powerList.indexOf('advance:prod:price:list') !==
              -1
            )?_c('el-button',{attrs:{"size":"mini","type":"primary"},on:{"click":function($event){return _vm.onRoomPrice(row)}}},[_vm._v("设置价格")]):_vm._e()]}}])})],1),_c('el-pagination',{attrs:{"current-page":_vm.parmes.currentPage,"page-sizes":[5, 10, 15, 20],"page-size":_vm.parmes.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.pagination.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1),_c('el-dialog',{attrs:{"close-on-click-modal":false,"title":"选择产品","visible":_vm.addVisible,"width":"60%"},on:{"update:visible":function($event){_vm.addVisible=$event}}},[(_vm.addVisible)?_c('select-products',{attrs:{"advanceId":_vm.parmes.advanceId},on:{"addData":_vm.onAddData,"close":function($event){_vm.addVisible = false}}}):_vm._e()],1),_c('el-dialog',{attrs:{"title":"添加库存","visible":_vm.stockVisible,"width":"30%"},on:{"update:visible":function($event){_vm.stockVisible=$event}}},[_c('div',{staticClass:"inner_top"},[_c('el-form',{attrs:{"label-width":"80px"}},[_c('el-form-item',{attrs:{"label":"活动库存"}},[_c('el-input',{attrs:{"placeholder":"请输入活动库存"},model:{value:(_vm.addFrom.stock),callback:function ($$v) {_vm.$set(_vm.addFrom, "stock", $$v)},expression:"addFrom.stock"}})],1)],1),_c('div',{staticStyle:{"text-align":"right"}},[_c('el-button',{on:{"click":function($event){_vm.stockVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addActiveRoom()}}},[_vm._v("确 定")])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }