import { render, staticRenderFns } from "./commodityManagement.vue?vue&type=template&id=1aff8986&scoped=true&"
import script from "./commodityManagement.vue?vue&type=script&lang=js&"
export * from "./commodityManagement.vue?vue&type=script&lang=js&"
import style0 from "./commodityManagement.vue?vue&type=style&index=0&id=1aff8986&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1aff8986",
  null
  
)

export default component.exports