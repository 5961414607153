<template>
    <div>
      <div class="roomList">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item>商品列表</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="search">
          <span>商品名称:</span>
          <el-input
            placeholder="请输入商品名称"
            v-model.trim="parmes.roomName"
          ></el-input>
          <span>活动状态:</span>
          <el-select
            clearable
            @clear="onClear(1)"
            v-model="parmes.status"
            placeholder="请选择"
          >
            <el-option
              v-for="item in statusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button type="primary" @click="onSearch">搜索</el-button>
          <el-button type="primary" @click="$router.push({ path: '/linkingActivity', query: { type:'reserve' } })">返回</el-button>
          <el-button type="primary" @click="addVisible = true"
            >添加商品</el-button
          >
        </div>
        <!-- -------表格----- -->
        <el-table
          :data="tableData"
          style="width: 100%"
          border
          @selection-change="onSelectChange"
        >
          <!-- <el-table-column
            align="center"
            type="selection"
            width="55"
          ></el-table-column>
          <el-table-column align="center" prop="detp" label="排序" width="120">
            <template slot-scope="{ row }">
              <el-input
                class="input-new-tag"
                v-if="row.inputVisible"
                v-model="row.dept"
                ref="saveTagInput"
                size="small"
                @input="onInput"
                @keyup.enter.native="handleInputConfirm(row)"
                @blur="handleInputConfirm(row)"
              >
              </el-input>
              <el-button v-else class="button-new-tag" @click="onChangeSort(row)">
                {{ row.dept }}
              </el-button>
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="advanceProdId"
            label="商品Id"
            width="120"
          >
          </el-table-column>
          <el-table-column align="center" prop="title" label="活动名称">
          </el-table-column>
          <el-table-column align="center" prop="days" label="提前预订天数">
          </el-table-column>
          <el-table-column align="center" prop="prodType" label="商品类型">
          </el-table-column>
          <el-table-column
            align="center"
            prop="showImg"
            label="展示图"
            width="120"
          >
            <template slot-scope="{ row }">
              <el-image
                style="width: 80px; height: 80px"
                :src="row.showImg"
                :preview-src-list="[row.showImg]"
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="prodName" label="商品名称" width="320">
          </el-table-column>
          <!-- <el-table-column align="center" prop="stock" label="活动库存">
          </el-table-column> -->
          <el-table-column align="center" prop="stock" label="活动库存" width="120">
            <template slot-scope="{ row }">
              <el-input
                class="input-new-tag"
                v-if="row.inputVisible"
                v-model="row.stock"
                ref="saveTagInput"
                size="small"
                @input="onInput"
                @keyup.enter.native="handleInputConfirm(row)"
                @blur="handleInputConfirm(row)"
              >
              </el-input>
              <el-button v-else class="button-new-tag" @click="onChangeSort(row)">
                {{ row.stock }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="strStatus" label="状态">
          </el-table-column>
          <el-table-column align="center" label="操作" width="350">
            <template slot-scope="{ row }">
              <el-button
                size="mini"
                @click="onUpAndDownRow(row)"
                v-if="
                  (row.status == 1) &&
                  $store.state.powerList.indexOf('advance:prod:list:modify') !==
                    -1
                "
                type="primary"
                >禁用
              </el-button>
              <el-button
                size="mini"
                @click="onUpAndDownRow(row)"
                v-else-if="
                  row.status == 2 &&
                  $store.state.powerList.indexOf('advance:prod:list:modify') !==
                    -1
                "
                type="primary"
                >启用
              </el-button>
              <!-- <el-button
                size="mini"
                type="primary"
                v-if="
                  $store.state.powerList.indexOf('connect:info:list:edit') !== -1
                "
                @click="
                  $router.push({
                    path: '/addActivityLinking',
                    query: { advanceId: row.advanceId },
                  })
                "
                >编辑</el-button
              > -->
              <el-button
                size="mini"
                type="primary"
                @click="onDelRow(row)"
                v-if="
                  $store.state.powerList.indexOf('advance:prod:list:delete') !==
                  -1
                "
                >删除</el-button
              >
              <el-button
                size="mini"
                type="primary"
                @click="onRoomPrice(row)"
                v-if="
                  $store.state.powerList.indexOf('advance:prod:price:list') !==
                  -1
                "
                >设置价格</el-button
              >
              <!-- <el-dropdown style="margin-left: 10px">
                <el-button size="mini" type="primary">
                  更多操作<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <el-button
                      style="width: 75px"
                      size="mini"
                      type="primary"
                      @click="onDelRow(row)"
                      v-if="$store.state.powerList.indexOf('hotel:room:list:delete') !== -1"
                      >删除</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      @click="$router.push(`/roomLog/${row.roomId}`)"
                      v-if="$store.state.powerList.indexOf('hotel:room:list:log') !== -1"
                      >查看日志</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      size="mini"
                      type="primary"
                      style="width: 75px"
                      @click="onShowAddress(row.roomId)"
                      >查看页面路径</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      size="mini"
                      v-if="$store.state.powerList.indexOf('hotel:room:list:add') !== -1"
                      @click="addByCodyRoom(row.roomId)"
                      >一键复制</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      size="mini"
                      style="width: 75px"
                      @click="onGOGroupBuy(row.roomId)"
                      >去拼团</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      size="mini"
                      style="width: 75px"
                      @click="onGOSecKill(row.roomId)"
                      >去秒杀</el-button
                    >
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <el-button
                      type="primary"
                      size="mini"
                      style="width: 75px"
                      @click="creatCode(row.roomId)"
                      >生成二维码</el-button
                    >
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="parmes.currentPage"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="parmes.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
  
      <!-- <div class="selectProducts">
        <el-dialog title="添加客房" :visible.sync="addVisible" width="70%">
          <div class="search">
            <span>客房名称:</span>
            <el-input
              v-model="roomListData.roomName"
              placeholder="请输入客房名称"
            ></el-input>
            <span>所属酒店:</span>
            <el-select
              v-model="roomListData.hotelId"
              clearable
              @clear="roomListData.hotelId = null"
              placeholder="请选择"
            >
              <el-option
                v-for="item in hotelList"
                :key="item.cateId"
                :label="item.cateName"
                :value="item.cateId"
              >
              </el-option>
            </el-select>
            <el-button type="primary" @click="getRoomListALL()">查找</el-button>
          </div>
          <el-table
            size="mini"
            border
            :data="roomData"
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55"> </el-table-column>
            <el-table-column
              align="center"
              prop="roomId"
              label="客房id"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="roomName"
              label="客房名称"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="hotelName"
              label="所属酒店"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="strSellWay"
              label="销售方式"
              width="width"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="strUnsubscribe"
              label="是否退订"
              width="width"
            >
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="{ row }">
                <el-button type="primary" size="mini" @click="onAdd([row])"
                  >添加</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div>
            <el-pagination
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="roomListData.currentPage"
              :page-sizes="[5, 10, 15, 20]"
              :page-size="roomListData.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="roomPagination.total"
            >
            </el-pagination>
          </div>
          <div class="pb_footer">
            <el-button @click="addVisible = false">取 消</el-button>
            <el-button type="primary" @click="onAdd(multipleSelection)"
              >确 定</el-button
            >
          </div>
        </el-dialog>
      </div> -->

      <el-dialog
      :close-on-click-modal="false"
      title="选择产品"
      :visible.sync="addVisible"
      width="60%"
    >
      <select-products v-if="addVisible" @addData="onAddData"  @close="addVisible = false" :advanceId ='parmes.advanceId'></select-products>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="addVisible = false">确 定</el-button>
      </span> -->
    </el-dialog>

      <el-dialog title="添加库存" :visible.sync="stockVisible" width="30%">
        <div class="inner_top">
          <el-form label-width="80px">
            <el-form-item label="活动库存">
              <el-input
                v-model="addFrom.stock"
                placeholder="请输入活动库存"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="text-align:right">
            <el-button @click="stockVisible = false">取 消</el-button>
            <el-button type="primary" @click="addActiveRoom()"
              >确 定</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </template>
    <script>
  import {
    modifyActiveCommodityStatus,
    deleteCommodity,
    modifyCommodityStock,
    getCommodityList,
    getRoomListAll,
    addActiveCommodity
  } from "../../api/room";
  import { isActivity } from "../../api/groupBuy";
  import { isActivityKill } from "../../api/seckillInfo";
  import { createEr2 } from "../../api/erCode";
//   import { allHotel } from "@/api/hotelList";
import selectProducts from "./selectProducts";
  export default {
    name: "roomList",
    components: {
    selectProducts,
  },
    data() {
      return {
        roomAddress: "",
        showAddress: false,
        QRcodeSize: "",
        codeVisible: false,
        roomId: "",
        imgUrl: "",
        tableData: [],
        pagination: {},
        ids: null,
        parmes: {
          currentPage: 1,
          pageSize: 5,
          advanceId: null,
          roomName: "",
          status: null,
        },
        hotelList: [],
        statusList: [
          {
            value: 0,
            label: "启用",
          },
          {
            value: 1,
            label: "禁用",
          },
        ],
        isShowOptions: [
          {
            value: -2,
            label: "全部",
          },
          {
            value: 1,
            label: "显示",
          },
          {
            value: 0,
            label: "隐藏",
          },
        ],
        sellWayList: [
          {
            value: 0,
            label: "全部",
          },
          {
            value: 1,
            label: "全金额",
          },
          {
            value: 2,
            label: "全积分",
          },
          {
            value: 3,
            label: "积分+金额",
          },
        ],
        stockVisible: false,
        addVisible: false,
        roomListData: {
          currentPage: 1,
          pageSize: 5,
          hotelId: null,
          roomName: "",
          status: 1,
        },
        roomData: [],
        roomPagination: "",
        addFrom: {
          stock: "",
          advanceId: "",
          prodIds: "",
          prodType:''
        },
        multipleSelection: [], //多选项
      };
    },
    created() {
      this.addFrom.advanceId = Number(this.$route.query.advanceId)
      this.parmes.advanceId = Number(this.$route.query.advanceId)
      this.getRoomList();
    //   this.getRoomListALL();
    //   this.getAllHotel();
    },
    methods: {
    //   async getRoomListALL() {
    //     const { data } = await getRoomListAll(this.roomListData);
    //     this.roomData = data.list;
    //     this.roomPagination = data.pagination;
    //   },
    //   // 查询所有酒店
    //   async getAllHotel() {
    //     const { data } = await allHotel();
    //     this.hotelList = data.data;
    //   },
      async onAdd(list) {
        console.log(list);
        if (list.length < 1) {
          return this.$message.error("请先选择产品！");
        }
        //   this.addVisible =
       let roomIdArr =  list.map(item=>{
          return item.roomId
        })
        this.addFrom.roomIds = roomIdArr.join(',')
        this.stockVisible = true;
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        console.log(this.multipleSelection);
      },
      async addActiveRoom(){
          if(this.addFrom.stock == ''){
              return this.$message.error("请输入活动库存！");
          }
        const { data } = await addActiveCommodity(this.addFrom);
        if (data.code == 0) {
          this.addVisible = false;
          this.stockVisible = false;
          this.getRoomList()
          }
      },
      onInput(value) {
        var reg = /^[0-9]*[1-9][0-9]*$/;
        var reg2 = /^-[0-9]*[1-9][0-9]*$/;
        if (!reg.test(value) && !reg2.test(value)) {
          return this.$message({
            type: "warning",
            message: "请输入整数!",
          });
        }
      },
      async getRoomList() {
        const { data } = await getCommodityList(this.parmes);
        this.pagination = data.pagination;
        data.list.map((item) => {
          item.inputVisible = false;
        });
        this.tableData = data.list;
      },
      onChangeSort(row) {
        row.inputVisible = true;
      },
      onSearch() {
        this.getRoomList();
      },
      onClear(status) {
        this.parmes.status = status;
      },
      onDelRow(row) {
        this.$confirm(`是否要删除这个活动?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            const { data } = await deleteCommodity({
                advanceProdId: row.advanceProdId,
            });
            if (data.code == 0) {
              this.getRoomList();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.ids = null;
            } else {
              this.$message({
                type: "error",
                message: "删除失败!",
              });
            }
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      },
      async onUpAndDownRow(row) {
        if (row.status == 1 ) {
          this.$confirm(`是否要操作这条数据?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              const { data } = await modifyActiveCommodityStatus({
                advanceProdId: row.advanceProdId,
                status: 2,
              });
              if (data.code == 0) {
                this.getRoomList();
                this.$message({
                  type: "success",
                  message: data.msg,
                });
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        } else if (row.status == 2) {
          this.$confirm(`是否要操这条数据?`, "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(async () => {
              const { data } = await modifyActiveCommodityStatus({
                advanceProdId: row.advanceProdId,
                status: 1,
              });
              if (data.code == 0) {
                this.getRoomList();
                this.$message({
                  type: "success",
                  message: data.msg,
                });
              } else {
                this.$message({
                  type: "error",
                  message: data.msg,
                });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
      },
      onSelectChange(selection) {
        var arr = [];
        selection.map((item) => {
          arr.push(item.roomId);
        });
        this.ids = arr;
      },
      async handleInputConfirm(row) {
        const { data } = await modifyCommodityStock({
            advanceProdId: row.advanceProdId,
          stock: row.stock,
        });
        if(data.code == 0){
          row.inputVisible = false;
          this.getRoomList();
        }
      },
      onAddData(arr, type) {
        console.log(arr, type);
        let IdArr =  arr.map(item=>{
          return item.id
        })
        this.addFrom.prodIds = IdArr.join(',')
        this.addFrom.prodType = type;
        this.stockVisible = true;
    //   this.formList.prodType = type;
    //   this.tableData[0].name = obj.name;
    //   this.formList.prodId = obj.id;
    //   this.dialogVisible = false;
    },
      handleSizeChange(num) {
        this.parmes.pageSize = num;
        this.getRoomList();
      },
      handleCurrentChange(num) {
        this.parmes.currentPage = num;
        this.getRoomList();
      },
      onRoomPrice(row) {
        this.$router.push({path:`/commodityManagementPrice/${row.advanceProdId}`,query:{advanceId:this.parmes.advanceId}});
      },
    },
  };
  </script>
    <style lang="less" scoped>
  .roomList {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      margin-top: 50px;
      .el-input {
        width: 200px;
      }
      span {
        margin: 0 20px;
      }
      .el-button {
        margin: 0 20px;
      }
    }
    .el-table {
      margin-top: 50px;
    }
    .el-pagination {
      margin-top: 50px;
      float: right;
    }
    .btn {
      margin-top: 50px;
    }
  }
  
  .selectProducts {
    .el-breadcrumb {
      height: 35px;
      border-bottom: 1px solid rgb(192, 191, 191);
    }
    .search {
      span {
        margin: 0 20px;
      }
      .el-input {
        width: 200px;
      }
      .el-select {
        margin: 0 20px;
      }
    }
    .line {
      .el-select {
        width: 150px;
      }
    }
    .el-table {
      margin-top: 20px;
    }
    /deep/.el-dialog__body {
      overflow: hidden !important;
    }
    .el-pagination {
      margin-top: 20px;
      float: right;
    }
    .pb_footer {
      margin-top: 50px;
      display: flex;
      align-items: center;
      // justify-content: flex-start;
      .el-button {
        margin-right: 20px;
      }
    }
  }
  </style>
    